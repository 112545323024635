import * as PIXI from 'pixi.js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FishKind } from '../../config';
import SlotMachine from '../../slotMachine';
import { FishIcon } from '../../slotMachine/fishing/icon/fishIcon';
import { getFishMultiplierValueText, getFishNameText, getFishRankText } from '../../utils/i18n';

import styles from './info.module.scss';

const FreeSpinFeature: React.VFC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'infoFeature' });

  const [infoBuyFeatureIcon, setInfoBuyFeatureIcon] = useState('');
  const [infoFishIcons, setInfoFishIcons] = useState<string[]>([]);

  useEffect(() => {
    const extractor: PIXI.Extract = SlotMachine.getInstance().getApplication().renderer.plugins[
      'extract'
    ] as PIXI.Extract;

    setInfoBuyFeatureIcon(extractor.base64(SlotMachine.getInstance().buyFeatureBtn?.btnContainer!));

    const fishRanks: FishKind[] = ['Rank1', 'Rank2', 'Rank3', 'Rank4', 'Rank5', 'Rank6', 'Rank7'];
    const fishIcons = fishRanks.map((rank) => new FishIcon(rank));

    setTimeout(() => {
      const fishBase64Icons = fishIcons.map((icon) => extractor.base64(icon));
      setInfoFishIcons(fishBase64Icons);
    }, 10);
  }, []);

  const featuresFeature: string[] = t('FeatureDesc', {
    returnObjects: true,
  });

  const featuresGambleChoice: string[] = t('FeatureGambleChoiceDesc', {
    returnObjects: true,
  });
  const featuresFishingGamble: string[] = t('FeatureFishingGambleDesc', {
    returnObjects: true,
  });

  const featuresScalingWins: string[] = t('FeatureScalingWinsDesc', {
    returnObjects: true,
  });

  const featuresBuyAFeature: string[] = t('FeatureBuyAFeatureDesc1', {
    returnObjects: true,
  });

  const getFishIconHtml = (fishKind: FishKind) => {
    return (
      <section>
        <p>
          RANK <span>{getFishRankText(fishKind)}</span>
        </p>
        <p>{getFishNameText(fishKind)}</p>
        <p>{getFishMultiplierValueText(fishKind)}</p>
        <p dangerouslySetInnerHTML={{ __html: t('FishingPrizeIconDesc') }} />
      </section>
    );
  };

  return (
    <section>
      <h1 className={styles.subtitle}>{t('FeatureSubTitle')}</h1>
      <div className={`${styles.p}`}>
        {featuresFeature.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
      <h1 className={styles.subtitle}>{t('FishingPrizeTitle')}</h1>
      {infoFishIcons.map((icon, index) => (
        <div key={index} className={styles.fishIcon}>
          <img draggable="false" src={icon} />
          {getFishIconHtml(`Rank${index + 1}` as FishKind)}
        </div>
      ))}
      <h1 className={styles.subtitle}>{t('FeatureGambleChoiceSubTitle')}</h1>
      <div className={`${styles.p}`}>
        {featuresGambleChoice.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
      <h1 className={styles.subtitle}>{t('FeatureFishingGambleSubTitle')}</h1>
      <div className={`${styles.p}`}>
        {featuresFishingGamble.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
      <h1 className={styles.subtitle}>{t('FeatureScalingWinsSubTitle')}</h1>
      <div className={`${styles.p}`}>
        {featuresScalingWins.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
      <h1 className={styles.subtitle}>{t('FeatureBuyAFeatureTitle')}</h1>
      <div className={styles.featureIcon}>
        <img draggable="false" src={infoBuyFeatureIcon} />
      </div>
      <div className={`${styles.p}`}>
        {featuresBuyAFeature.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'infoFeature' });

  return (
    <section>
      <h1 className={styles.title}>{t('FeatureTitle')}</h1>
      <FreeSpinFeature />
    </section>
  );
};

export default Features;
