import * as PIXI from 'pixi.js';

import { Variables } from '../../config';
import { AutoResizeTextStyle } from '../components/autoResizeText';

const font = Variables.FONT_FAMILY;

// Button Text Style
export const buyFeatureBtnTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  align: 'center',
  fontSize: 70,
  lineHeight: 70,
  maxWidth: 218,
  maxHeight: 114,
  fill: ['#FFCC00', '#F6FF00'],
  fillGradientStops: [0, 0.63],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  //  fill: Colors.GAME_COLOR,
  letterSpacing: 0,
  lineJoin: 'round',
  stroke: '#000000',
  strokeThickness: 10,
};

export const buyFeatureTitleStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 671,
  fontSize: 86,
  fill: ['#00D0FF', '#FFE24C'],
  fillGradientStops: [0.18, 0.58],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontWeight: 'bold',
  align: 'center',
  stroke: '#201307',
  strokeThickness: 10,
  lineJoin: 'round',
};

export const buyFeatureNormalTitleTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 340,
  fontSize: 54,
  fill: '#0066C5',
  fontWeight: 'bold',
  align: 'center',
  stroke: '#ffffff',
  strokeThickness: 6,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(8, 0),
  dropShadowBlur: 1,
  dropShadowDistance: Math.sqrt(8 * 8),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.9,
};

export const buyFeatureNormalCostTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 360,
  fontSize: 60,
  fill: '#FFE24C',
  fontWeight: 'bold',
  align: 'center',
  stroke: '#000000',
  strokeThickness: 6,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowBlur: 2,
  dropShadowDistance: Math.sqrt(4 * 4),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};
export const buyFeatureSpecialTitleTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 340,
  fontSize: 54,
  fill: '#FF0000',
  fontWeight: 'bold',
  align: 'center',
  stroke: '#ffffff',
  strokeThickness: 6,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(8, 0),
  dropShadowBlur: 1,
  dropShadowDistance: Math.sqrt(8 * 8),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.9,
};
export const buyFeatureSpecialDescriptionTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 400,
  fontSize: 27,
  fill: ['#FF2408', '#FFD912'],
  fillGradientStops: [0.36, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontWeight: 'bold',
  align: 'center',
  stroke: '#ffffff',
  strokeThickness: 6,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(4 * 4),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};

export const buyFeatureBetDescriptionTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 48,
  fontFamily: font,
  maxWidth: 700,
  fill: '#FFD912',
  fontWeight: '700',
  stroke: '#000000',
  strokeThickness: 6,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(4 * 4),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};

export const betValueStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 64,
  fontFamily: font,
  fill: '#FFE24D',
  letterSpacing: 0,
  fontWeight: '700',
  align: 'center',
  maxWidth: 450,
  stroke: '#000000',
  strokeThickness: 6,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(4 * 4),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};

export const totalCostTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 45,
  fontFamily: font,
  letterSpacing: 0,
  align: 'left',
  maxWidth: 700,
  fill: '#001950',
  fontWeight: '700',
  stroke: '#ffffff',
  strokeThickness: 6,
  lineJoin: 'round',
};

export const confirmTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 900,
  fontSize: 55,
  fill: '#FFD912',
  fontWeight: 'bold',
  align: 'center',
  lineHeight: 70,
  stroke: '#000000',
  strokeThickness: 6,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(8, 0),
  dropShadowBlur: 2,
  dropShadowDistance: Math.sqrt(8 * 8),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};

export const confirmTotalCostTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 900,
  fontSize: 60,
  fill: ['#FFD912', '#FF6B00'],
  fillGradientStops: [0, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontWeight: 'bold',
  align: 'center',
  stroke: '#000000',
  strokeThickness: 8,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(4, 0),
  dropShadowBlur: 4,
  dropShadowDistance: Math.sqrt(4 * 4),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};
export const totalCostTextAmountStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: Variables.FONT_FAMILY,
  maxWidth: 900,
  fontSize: 110,
  fill: '#FFA800',
  fontWeight: 'bold',
  align: 'center',
  stroke: '#FFFFFF',
  strokeThickness: 8,
  lineJoin: 'round',
  dropShadow: true,
  dropShadowAngle: Math.atan2(8, 0),
  dropShadowBlur: 2,
  dropShadowDistance: Math.sqrt(8 * 8),
  dropShadowColor: '#000',
  dropShadowAlpha: 0.7,
};
