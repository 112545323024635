import { config } from '../config/config';
import { BonusStatus, GameMode } from '../consts';
import { getFragmentData } from '../gql';
import { OutcomeFragment, WagerFragment } from '../gql/__generated__/graphql';
import { setNextResult } from '../gql/cache';
import { outcomeFragment, wagerFragment } from '../gql/fragment';
//import { betBonusRewardFragment, userBonusFragment } from '../gql/fragment';
import { BonusKind } from '../slotMachine/config/bonusInfo';
import { Combos, Icon } from '../slotMachine/d';
import { IBonusData } from '../types';

import { getBetData, getWagerSettingsFromWager, getWagerStorageFromWager } from './fragments';
import { getNonNullableValue } from './helper';
//import { Combos, Icon } from '../slotMachine/d';
import { findBonusInfoByBonusId } from './specific';

export const saveReelPosition = (reelPositions: number[]): void => {
  const positions = reelPositions.toString();
  sessionStorage.setItem('positions', window.btoa(positions));
  window.dispatchEvent(new CustomEvent('setpos'));
};

export const getGameModeByBonusId = (bonusId: string): GameMode => {
  const BonusKindToGameMode: Record<BonusKind, GameMode> = {
    FreeSpins: GameMode.FREE_SPINS,
    BuyFeatureNml: GameMode.BUY_FEATURE,
    BuyFeatureSp: GameMode.BUY_FEATURE,
  };
  const bonusKind = getBonusKind(bonusId);
  return bonusKind != undefined ? BonusKindToGameMode[bonusKind]! : GameMode.BASE_GAME;
};

//TO DO
export function getBonusFromRewards(wager: WagerFragment, outcome: OutcomeFragment): IBonusData | undefined {
  //type RewardType = NonNullable<NonNullable<ISettledBet>['rewards']>[number];
  //type BetBonusRewardType = FragmentType<typeof betBonusRewardFragment> & { __typename: 'BetBonusReward' };

  const bonus = outcome.rewards && outcome.rewards.find((bonus) => bonus.type === 'BONUS');

  const wagerSettings = getWagerSettingsFromWager(wager);
  const wagerStorage = getWagerStorageFromWager(wager);

  if (!wagerSettings) return;
  if (!wagerStorage) return;

  if (bonus) {
    return {
      packageId: bonus?.entityId as GameMode,
      gameMode: bonus?.entityId as GameMode,
      rounds: bonus?.value,
      roundsPlayed: 0,
      state: BonusStatus.OPEN,
      coinAmount: wager.coinAmount!,
      coinValue: wager.coinValue!,
      originalReelPositions: getNonNullableValue(wagerSettings.originalReelPositions),
      isBuyFeature:
        wagerSettings.gameMode === GameMode.BUY_FEATURE || wagerSettings.originalGameMode === GameMode.BUY_FEATURE,
    };
  }

  return {
    packageId: wagerSettings.packageId!,
    gameMode: wagerSettings.gameMode as GameMode,
    rounds: wagerSettings.rounds!,
    roundsPlayed: wagerStorage?.roundsPlayed!,
    state: BonusStatus.OPEN,
    coinAmount: wager.coinAmount!,
    coinValue: wager.coinValue!,
    originalReelPositions: getNonNullableValue(wagerSettings.originalReelPositions)!,
    isBuyFeature:
      wagerSettings.gameMode === GameMode.BUY_FEATURE || wagerSettings.originalGameMode === GameMode.BUY_FEATURE,
  };
}

export const getFreeSpinBonus = () => {
  const wager = getFragmentData(wagerFragment, getBetData(setNextResult()!).wager)!;
  const outcomes = getFragmentData(outcomeFragment, getBetData(setNextResult()!).outcomes);

  const bonusOutcome = outcomes?.find((outcome) => {
    return outcome.rewards?.find((reward) => {
      return reward.entityId === 'FREE_SPINS';
    });
  });

  if (bonusOutcome != undefined) {
    return getBonusFromRewards(wager, bonusOutcome);
  }

  return undefined;
};

export const getBonusKind = (bonusId: string): BonusKind | undefined => {
  return findBonusInfoByBonusId(bonusId)?.bonusKind;
};

export const calcPercentage = (initialValue: number, percent: number): number => {
  return (initialValue / 100) * percent;
};

//TO DO
export function getCombosDataFromIcon(icon: Icon): Combos {
  const data = config.payTableData.find((data) => data.slug === icon.id);
  return data?.combos ?? [];

  /*  return icon.combos
    .flatMap((combo) => {
      const coinReward = combo.rewards.find((v) => v.type === 'COINS');
      if (coinReward) {
        return {
          pattern: combo.pattern,
          multiplier: coinReward.multiplier,
        };
      }
      return [];
    })
    .sort((a, b) => {
      const patternA = a.pattern;
      const patternB = b.pattern;
      if (patternA < patternB) {
        return 1;
      }
      if (patternA > patternB) {
        return -1;
      }
      return 0;
    });
    */
}
